import { default as indexQzRIxt1m3BMeta } from "/opt/atlassian/pipelines/agent/build/pages/[businessCode]/[group]/[invoiceCode]/index.vue?macro=true";
import { default as index21ZtUTTlqpMeta } from "/opt/atlassian/pipelines/agent/build/pages/[type]/[businessCode]/[group]/[invoiceCode]/index.vue?macro=true";
import { default as indexLJTDmrIjbUMeta } from "/opt/atlassian/pipelines/agent/build/pages/change/email/index.vue?macro=true";
import { default as indexQ5iU2RHKGiMeta } from "/opt/atlassian/pipelines/agent/build/pages/change/password/index.vue?macro=true";
import { default as crezco_45loadingHnIZxvIe8qMeta } from "/opt/atlassian/pipelines/agent/build/pages/crezco-loading.vue?macro=true";
import { default as indexOD9t3F2bSJMeta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as Invoices6b2ZuYgxRhMeta } from "/opt/atlassian/pipelines/agent/build/pages/Invoices.vue?macro=true";
import { default as indexhy6eC1scWCMeta } from "/opt/atlassian/pipelines/agent/build/pages/login/index.vue?macro=true";
import { default as indexpB4a7n7sEkMeta } from "/opt/atlassian/pipelines/agent/build/pages/validate/email/index.vue?macro=true";
import { default as indexZp5UTsu2PLMeta } from "/opt/atlassian/pipelines/agent/build/pages/validate/password/index.vue?macro=true";
import { default as index8FFSDYc3tYMeta } from "/opt/atlassian/pipelines/agent/build/pages/validate/phone/index.vue?macro=true";
import { default as indexWqccDZ8xAyMeta } from "/opt/atlassian/pipelines/agent/build/pages/widget/[businessCode]/[group]/index.vue?macro=true";
import { default as indexTmLBsw2Q5RMeta } from "/opt/atlassian/pipelines/agent/build/pages/widget/chat/[businessCode]/[group]/index.vue?macro=true";
import { default as indexsSSS6UHpLWMeta } from "/opt/atlassian/pipelines/agent/build/pages/widget/conversations/[businessCode]/[group]/index.vue?macro=true";
import { default as indexP370qIKL7nMeta } from "/opt/atlassian/pipelines/agent/build/pages/widget/documents/[businessCode]/[group]/index.vue?macro=true";
import { default as indexteqOspcKIHMeta } from "/opt/atlassian/pipelines/agent/build/pages/widget/payments/[businessCode]/[group]/index.vue?macro=true";
export default [
  {
    name: "businessCode-group-invoiceCode",
    path: "/:businessCode()/:group()/:invoiceCode()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[businessCode]/[group]/[invoiceCode]/index.vue")
  },
  {
    name: "invoice-page",
    path: "/:type()/:businessCode()/:group()/:invoiceCode()",
    meta: index21ZtUTTlqpMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[type]/[businessCode]/[group]/[invoiceCode]/index.vue")
  },
  {
    name: "change-email",
    path: "/change/email",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/change/email/index.vue")
  },
  {
    name: "change-password",
    path: "/change/password",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/change/password/index.vue")
  },
  {
    name: "crezco-loading",
    path: "/crezco-loading",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/crezco-loading.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue")
  },
  {
    name: "Invoices",
    path: "/Invoices",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/Invoices.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/login/index.vue")
  },
  {
    name: "validate-email",
    path: "/validate/email",
    meta: indexpB4a7n7sEkMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/validate/email/index.vue")
  },
  {
    name: "validate-password",
    path: "/validate/password",
    meta: indexZp5UTsu2PLMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/validate/password/index.vue")
  },
  {
    name: "validate-phone",
    path: "/validate/phone",
    meta: index8FFSDYc3tYMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/validate/phone/index.vue")
  },
  {
    name: "widget-businessCode-group",
    path: "/widget/:businessCode()/:group()",
    meta: indexWqccDZ8xAyMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/widget/[businessCode]/[group]/index.vue")
  },
  {
    name: "widget-chat-businessCode-group",
    path: "/widget/chat/:businessCode()/:group()",
    meta: indexTmLBsw2Q5RMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/widget/chat/[businessCode]/[group]/index.vue")
  },
  {
    name: "widget-conversations-businessCode-group",
    path: "/widget/conversations/:businessCode()/:group()",
    meta: indexsSSS6UHpLWMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/widget/conversations/[businessCode]/[group]/index.vue")
  },
  {
    name: "widget-documents-businessCode-group",
    path: "/widget/documents/:businessCode()/:group()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/widget/documents/[businessCode]/[group]/index.vue")
  },
  {
    name: "widget-payments-businessCode-group",
    path: "/widget/payments/:businessCode()/:group()",
    meta: indexteqOspcKIHMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/widget/payments/[businessCode]/[group]/index.vue")
  }
]